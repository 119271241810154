import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import './DepotStock.css'; // Réutilisation des styles de DepotStock.css

const RetraitStock = ({ article, onSubmit, onCancel }) => {
  const [quantiteRetirer, setQuantiteRetirer] = useState('');
  const [code_article, setArticleCode] = useState(article ? article.code_article : '');
  const [successMessage, setSuccessMessage] = useState('');
  const [operateur, setOperateur] = useState('');  // Nouveau champ pour l'opérateur
  const navigate = useNavigate(); // Initialize useNavigate
  const [isSubmitting, setIsSubmitting] = useState(false); // État pour désactiver le bouton

useEffect(() => {
    if (article) {
      setArticleCode(article.code_article);
    }

    // Récupérer le nom de l'opérateur à partir du token JWT
        const token = localStorage.getItem('token');
        if (token) {
          const payload = JSON.parse(atob(token.split('.')[1]));  // Décoder le token JWT pour obtenir les infos
          setOperateur(payload.username);  // Enregistrer le nom de l'opérateur
        }

  }, [article]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation de la quantité saisie
    if (!quantiteRetirer || isNaN(quantiteRetirer) || parseInt(quantiteRetirer) <= 0) {
      alert('Veuillez entrer un entier valide pour la quantité.');
      return;
    }

    if (parseInt(quantiteRetirer) > article.quantite) {
      alert('La quantité à retirer dépasse le stock disponible.');
      return;
    }

   if (isSubmitting) return;
    setIsSubmitting(true); // Désactive le bouton au début de la soumission

    try {
      const token = localStorage.getItem('token');

      // Appel à l'API pour retirer la quantité du stock
      await axios.put(
        `${process.env.REACT_APP_API_URL}/articles/${article.id}/retirer-quantite`,
        { quantite: parseInt(quantiteRetirer) },
        { headers: { Authorization: `Bearer ${token}` } }
      );

         // Enregistrement de l'opération
         await axios.post(
           `${process.env.REACT_APP_API_URL}/historique-retrait`,
           {
             quantite: parseInt(quantiteRetirer),
             code_article: code_article,
             operateur: operateur,
           },
           { headers: { Authorization: `Bearer ${token}` } }
         );

      setSuccessMessage('Enregistrement réussi');
          setTimeout(() => {
            setSuccessMessage('');
            if (onSubmit) {
              onSubmit(); // Si la fonction onSubmit est définie
            }
            navigate('/Home'); // Redirection après 2 secondes
          }, 2000);
    } catch (error) {
      console.error('Erreur lors du retrait de la quantité :', error);
      alert('Une erreur est survenue lors du retrait de la quantité.');
    }
    setIsSubmitting(false); // Réactive le bouton une fois la soumission terminée
  };

  return (
    <div className="form-box">
      <h3>Retrait de stock</h3>
      <p><strong>Code Article :</strong> {article.code_article}</p>
      <p><strong>Désignation :</strong> {article.designation_article}</p>
      <p><strong>Quantité actuelle :</strong> {article.quantite}</p>
      <p><strong>Type :</strong> {article.type_article}</p>
      <p><strong>Localisation :</strong> {article.localisation_article}</p>

      <form onSubmit={handleSubmit} className="form-container2">
        <label>
          Quantité à retirer :
          <input
            type="number"
            value={quantiteRetirer}
            onChange={(e) => setQuantiteRetirer(e.target.value)}
            min="1"
            required
          />
        </label>
        <div style={{ marginTop: '10px' }}>
          <button type="submit" className={isSubmitting ? 'button-disabled' : 'button'}  disabled={isSubmitting}>{isSubmitting ? 'Enregistrement...' : 'Enregistrer'}</button>
          <button type="button" onClick={onCancel}>Annuler</button>
        </div>
      </form>
    </div>
  );
};

export default RetraitStock;
