import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import Webcam from 'react-webcam';
import './Anomalie.css';  // Import the CSS file

const Anomalie = ({ device, onSubmit, onCancel, showNavbar = true }) => {
  const [numero_inventaire, setNumeroInventaire] = useState(device ? device.numero_inventaire : '');
  const [anomlie, setAnomalieConstatee] = useState(device ? device.anomlie : '');
  const [operateur, setOperateur] = useState('');  // Nouveau champ pour l'opérateur
  const [formVisible, setFormVisible] = useState(true); // Add state to control form visibility
  const [successMessage, setSuccessMessage] = useState('');

  const [photoSupplData, setPhotoSupplData] = useState([]); // Liste des photos supplémentaires
  const [showCamera, setShowCamera] = useState(false);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment');
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);

  const [emailDestinataires, setEmailDestinataires] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');

  useEffect(() => {
  const fetchEmails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/emailDestinataires`);
      console.log('E-mails récupérés :', response.data); // Log pour vérifier
      setEmailDestinataires(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des emails:', error);
    }
  };
  fetchEmails();
  }, []);


  useEffect(() => {
    if (device) {
      setNumeroInventaire(device.numero_inventaire);
      setAnomalieConstatee(device.anomlie);
    }

    // Récupérer le nom de l'opérateur à partir du token JWT
        const token = localStorage.getItem('token');
        if (token) {
          const payload = JSON.parse(atob(token.split('.')[1]));  // Décoder le token JWT pour obtenir les infos
          setOperateur(payload.username);  // Enregistrer le nom de l'opérateur
        }

  }, [device]);

  // Fonction pour ajouter une photo prise avec la caméra
  const handleTakePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotoSupplData((prevPhotos) => [...prevPhotos, { type: 'camera', data: imageSrc }]);
    setShowCamera(false);
  };

  // Fonction pour ajouter un fichier sélectionné
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Supporter plusieurs fichiers
    const newFiles = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({ type: 'file', data: reader.result });
        };
      });
    });
    Promise.all(newFiles).then((newFileData) => {
      setPhotoSupplData((prevPhotos) => [...prevPhotos, ...newFileData]);
    });
  };

  // Fonction pour supprimer une photo/fichier
  const handleDeletePhoto = (index) => {
    setPhotoSupplData(photoSupplData.filter((_, i) => i !== index));
  };

  // Fonction pour enregistrer chaque photo dans une table distincte
const savePhotosToSeparateTable = async () => {
  const token = localStorage.getItem('token');
  const date = new Date().toISOString();

  // Construire les données à envoyer
  const photoData = {
    numero_inventaire,
    date,
    operateur,
    anomlie,
    destinataire: selectedEmail,
    photos: photoSupplData.map(photo => photo.data)
  };

  try {
    // Envoyer toutes les photos en une seule requête
    await axios.post(
      `${process.env.REACT_APP_API_URL}/photos_anomalies`,
      photoData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement des photos :', error);
  }
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const updatedAnomalies = {
      numero_inventaire: numero_inventaire,
      anomlie: anomlie,
      operateur: operateur,  // Ajout de l'opérateur ici
    };

    try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/anomlies`,
          updatedAnomalies,
          { headers: { Authorization: `Bearer ${token}` } }
        );

      // Enregistrer les photos dans la table séparée après la soumission de l'anomalie
      await savePhotosToSeparateTable();

      // Reset the form fields
      setNumeroInventaire('');
      setAnomalieConstatee('');
      setSuccessMessage('Enregistrement réussi');
      setFormVisible(false);
      setTimeout(() => {
        setSuccessMessage('');
        onSubmit();  // Call the onSubmit function passed as prop
        onCancel();
      }, 5000); // Delay of 2 seconds before calling onSubmit
    } catch (error) {
      console.error('Error submitting form: ', error);
    }
  };

  return (
    <div>
    {showNavbar && <Navbar />}
    {formVisible && (
      <form onSubmit={handleSubmit}>
      <h2 className="titre_formulaire">Signaler une anomalie</h2>
      <div className="form-container2">
        <div className="form-group">
           <input
              type="text"
              id="numero_inventaire"
              placeholder="Numero inventaire"
              value={numero_inventaire}
              onChange={(e) => setNumeroInventaire(e.target.value)}
              required
              disabled
           />
        </div>

      <div className="form-group">
      <select
        id="emailDestinataire"
        value={selectedEmail}
        onChange={(e) => setSelectedEmail(e.target.value)}
        required
      >
        <option value="">-- Choisir un email --</option>
        {emailDestinataires.map((destinataire) => (
          <option key={destinataire.id} value={destinataire.email}>
            {destinataire.email}
          </option>
        ))}
      </select>
    </div>

        <div className="form-group">
          <input
            type="text"
            id="anomlie"
            placeholder="Anomalie constatée"
            value={anomlie}
            onChange={(e) => setAnomalieConstatee(e.target.value)}
            required
          />
        </div>

          {/* Section de fichier */}
          <div className="form-group">
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>

          {/* Section de la caméra */}
          <div className="form-group">
            <button type="button" onClick={() => setShowCamera(true)}>
              Prendre une photo
            </button>
            {showCamera && (
              <div>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{ facingMode: cameraFacingMode }}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <button type="button" onClick={handleTakePhoto}>
                  Prendre la photo
                </button>
                <button type="button" onClick={() => setShowCamera(false)}>
                  Annuler
                </button>
              </div>
            )}
          </div>

          {/* Tableau de prévisualisation des photos supplémentaires */}
          <div className="form-group">
            <div className="photos-table">
              {photoSupplData.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Aperçu</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {photoSupplData.map((photo, index) => (
                      <tr key={index}>
                        <td className="actions-cell">
                          <img
                            src={photo.data}
                            alt={`Photo supplémentaire ${index + 1}`}
                            style={{ width: '100px', height: '100px' }}
                          />
                        </td>
                        <td className="actions-cell">
                          <button type="button" onClick={() => handleDeletePhoto(index)}>
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Aucune photo ajoutée.</p>
              )}
            </div>
          </div>

        <div id="colB">
        <div id="colC"><button class="btn btn-lg btn-primary" type="submit">Enregistrer</button></div>
        <div id="colC"><button class="btn btn-lg btn-primary" type="button" onClick={onCancel}>Annuler</button></div>
        </div>
        </div>

      </form>
      )}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default Anomalie;




