import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DepotStock.css'; // Import du fichier CSS
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const DepotStock = ({ article, onSubmit, onCancel }) => {
  const [quantiteAjouter, setQuantiteAjouter] = useState('');
  const [code_article, setArticleCode] = useState(article ? article.code_article : '');
  const [successMessage, setSuccessMessage] = useState('');
  const [operateur, setOperateur] = useState('');  // Nouveau champ pour l'opérateur
  const navigate = useNavigate(); // Initialize useNavigate
  const [isSubmitting, setIsSubmitting] = useState(false); // État pour désactiver le bouton

  useEffect(() => {
    if (article) {
      setArticleCode(article.code_article);
    }

    // Récupérer le nom de l'opérateur à partir du token JWT
        const token = localStorage.getItem('token');
        if (token) {
          const payload = JSON.parse(atob(token.split('.')[1]));  // Décoder le token JWT pour obtenir les infos
          setOperateur(payload.username);  // Enregistrer le nom de l'opérateur
        }

  }, [article]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation de la quantité saisie
    if (!quantiteAjouter || isNaN(quantiteAjouter) || parseInt(quantiteAjouter) <= 0) {
      alert('Veuillez entrer un entier valide pour la quantité.');
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true); // Désactive le bouton au début de la soumission

    try {
      const token = localStorage.getItem('token');

      // Appel à l'API pour ajouter la quantité au stock
      await axios.put(
        `${process.env.REACT_APP_API_URL}/articles/${article.id}/ajouter-quantite`,
        { quantite: parseInt(quantiteAjouter) },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Enregistrement de l'opération
      await axios.post(
        `${process.env.REACT_APP_API_URL}/historique-depot`,
        {
          quantite: parseInt(quantiteAjouter),
          code_article: code_article,
          operateur: operateur,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

            setSuccessMessage('Enregistrement réussi');
            setTimeout(() => {
              setSuccessMessage('');
              if (onSubmit) {
                          onSubmit(); // Call onSubmit if it's a function
              }
              navigate('/Home'); // Redirect to home page after submission
            }, 2000); // Delay of 2 seconds before calling onSubmit
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la quantité :', error);
      alert('Une erreur est survenue lors de l\'ajout de la quantité.');
    }
setIsSubmitting(false); // Réactive le bouton une fois la soumission terminée
  };

  return (
    <div className="form-box">
      <h3>Dépôt de stock</h3>
      <p><strong>Code Article :</strong> {article.code_article}</p>
      <p><strong>Désignation :</strong> {article.designation_article}</p>
      <p><strong>Quantité actuelle :</strong> {article.quantite}</p>
      <p><strong>Type :</strong> {article.type_article}</p>
      <p><strong>Localisation :</strong> {article.localisation_article}</p>

<form onSubmit={handleSubmit} className="form-container2">
  <label>
    Quantité à ajouter :
    <input
      type="number"
      value={quantiteAjouter}
      onChange={(e) => setQuantiteAjouter(e.target.value)}
      min="1"
      required
    />
  </label>
  <div style={{ marginTop: '10px' }}>
    <button type="submit" className={isSubmitting ? 'button-disabled' : 'button'}  disabled={isSubmitting}>{isSubmitting ? 'Enregistrement...' : 'Enregistrer'}</button>
    <button type="button" onClick={onCancel}>Annuler</button>
  </div>
</form>
    </div>
  );
};

export default DepotStock;
