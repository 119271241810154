import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import * as XLSX from 'xlsx';
import './HistoriqueDepotRetrait.css';  // Import the CSS file

const HistoriqueDepotRetrait = () => {
  const [historique, setHistorique] = useState([]);
  const [typeOperation, setTypeOperation] = useState('retrait'); // Par défaut, "Dépot"
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  useEffect(() => {
    fetchHistorique();
  }, [typeOperation, startDate, endDate]);

  const fetchHistorique = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/historique-${typeOperation}`,
        {
          params: { startDate, endDate },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setHistorique(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'historique :', error);
      setHistorique([]);
    }
  };

  const exportToCSV = () => {
    if (historique.length === 0) {
      alert('Aucune donnée à exporter.');
      return;
    }

    const headers = ['Code article', 'Quantité', 'Date', 'Opérateur'];
    const rows = historique.map((entry) => [
      entry.code_article,
      entry.designation_article,
      entry.quantite,
      entry.date,
      entry.operateur,
    ]);

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${typeOperation}_${today}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToExcel = () => {
    if (historique.length === 0) {
      alert('Aucune donnée à exporter.');
      return;
    }

    const data = historique.map((entry) => ({
      'Code article': entry.code_article,
      'Désignation': entry.designation_article,
      'Quantité': entry.quantite,
      'Date': entry.date,
      'Opérateur': entry.operateur,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Historique');
    XLSX.writeFile(workbook, `${typeOperation}_${today}.xlsx`);
  };

  return (
    <div>
      <Navbar />
      <h2>Historique des mouvements de stock</h2>
      <div className="date-filters">
        <label>Type d'opération :</label>
        <select
          class="date-field"
          value={typeOperation}
          onChange={(e) => setTypeOperation(e.target.value)}
        >
          <option value="depot">Dépot</option>
          <option value="retrait">Retrait</option>
        </select>
          <label>Du :</label>
          <input
            type="date"
            class="date-field"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label>Au :</label>
          <input
            type="date"
            class="date-field"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
      </div>
      <div className="export-buttons">
        <div className="colExcel"><button onClick={exportToCSV}>Exporter en CSV</button></div>
        <div className="colExcel"><button onClick={exportToExcel}>Exporter en Excel</button></div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Code article</th>
            <th>Quantité</th>
            <th>Date</th>
            <th>Opérateur</th>
          </tr>
        </thead>
        <tbody>
          {historique.map((entry, index) => (
            <tr key={index}>
              <td>{entry.code_article}</td>
              <td>{entry.designation_article}</td>
              <td>{entry.quantite}</td>
              <td>{entry.date}</td>
              <td>{entry.operateur}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HistoriqueDepotRetrait;
