import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import './CGU.css';

const CGU = () => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailTo, setEmailTo] = useState('support@techmaintsystems.fr');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  const handleEmailClick = () => {
    setShowEmailForm(true);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');  // Récupère le token depuis le localStorage

    const emailData = {
      to: emailTo,
      subject: emailSubject,
      text: emailMessage,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/send-email`,
        emailData,
        { headers: { Authorization: `Bearer ${token}` } }  // Ajout du header d'autorisation
      );
      alert('Email envoyé avec succès !');
      setShowEmailForm(false);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
      alert('Erreur lors de l\'envoi de l\'email');
    }
  };

  return (
    <div>
      <Navbar />
      <div className="cgu-container">
        <h2>Nous contacter</h2>
        <h3>Adresse email</h3>
        <p onClick={handleEmailClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
          support@techmaintsystems.fr
        </p>

        {showEmailForm && (
          <form onSubmit={handleSendEmail} className="email-form">
            {/*<label>
              Destinataire:
              <input
                type="email"
                value={emailTo}
                onChange={(e) => setEmailTo(e.target.value)}
                required
              />
            </label>*/}
            <label>
              Objet:
              <input
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                required
              />
            </label>
            <label>
              Message:
              <textarea
                value={emailMessage}
                onChange={(e) => setEmailMessage(e.target.value)}
                required
              />
            </label>
            <button type="submit">Envoyer</button>
            <button type="button" onClick={() => setShowEmailForm(false)}>Annuler</button>
          </form>
        )}

        <h3>Numéro de téléphone</h3>
                <p>
                  <a href="tel:0601051490" style={{ color: 'blue', textDecoration: 'underline' }}>
                    06 01 05 14 90
                  </a>
                </p>
      </div>
    </div>
  );
};

export default CGU;
