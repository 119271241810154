import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import "./EmailManager.css"; // Ajoutez un fichier CSS si nécessaire

const EmailManager = ({onSubmit, showNavbar = true }) => {
  const [emailDestinataires, setEmailDestinataires] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/home"); // Remplacez "/home" par l'URL cible.
  };


  // Charger les emails existants au montage du composant
  // Définir fetchEmails ici pour qu'elle soit réutilisable
  const fetchEmails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/emailDestinataires`);
      setEmailDestinataires(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des emails:", error);
      setErrorMessage("Erreur lors du chargement des emails.");
    }
  };

  // Charger les emails existants au montage du composant
  useEffect(() => {
    fetchEmails();
  }, []);

  // Ajouter un nouvel email
  const handleAddEmail = async (e) => {
  e.preventDefault();
    if (!newEmail) {
      setErrorMessage("Veuillez entrer une adresse email valide.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/emailDestinataires`, {
        email: newEmail,
      });

      setEmailDestinataires((prevEmails) => [
        ...prevEmails,
        { id: response.data.id, email: newEmail },
      ]);
      setNewEmail(""); // Réinitialise le champ
      setSuccessMessage("Adresse email ajoutée avec succès.");
      await fetchEmails(); // Recharge les données à jour
      setErrorMessage(""); // Efface les messages d'erreur
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'email:", error);
      setErrorMessage("Erreur lors de l'ajout de l'email. Veuillez réessayer.");
    } finally {
         setLoading(false);
       }
  };

  // Supprimer un email
  const handleDeleteEmail = async (id) => {
  setLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/emailDestinataires/${id}`);
      setEmailDestinataires((prevEmails) => prevEmails.filter((email) => email.id !== id));
      setSuccessMessage("Adresse email supprimée avec succès.");
      await fetchEmails(); // Recharge les données à jour
      setErrorMessage("");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'email:", error);
      setErrorMessage("Erreur lors de la suppression de l'email.");
    } finally {
         setLoading(false);
       }
  };

  return (
    <div>
      {showNavbar && <Navbar />}
      <div className="form-box">
        <h2 className="titre_formulaire" class="text-body-secondary">Gestion des Adresses Email</h2>
        <form onSubmit={handleAddEmail}>
        <div className="form-container2">
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        {/* Formulaire pour ajouter une adresse email */}
        <div className="email-form">
          <h3>Ajouter une Adresse Email</h3>
          <div className="form-group">
          <input
            type="email"
            placeholder="Nouvelle adresse email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          </div>
 <div id="colB">
                                                 <div className="form-group" id="colC">
                                                   <button class="btn btn-lg btn-primary" type="submit">Enregistrer</button>
                                                 </div>


                                                   <div className="form-group" id="colC">
                                                     <button class="btn btn-lg btn-primary" type="button" onClick={handleCancel}>Annuler</button>
                                                   </div>

                                               </div>
        </div>

{/* Liste des adresses email */}
<div className="email-list">
  <h3>Liste des Adresses Email</h3>
  {emailDestinataires.length > 0 ? (
    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {emailDestinataires.map((destinataire) => (
          <tr key={destinataire.id}>
            <td className="actions-cell">{destinataire.email}</td>
            <td className="actions-cell">
              <button onClick={() => handleDeleteEmail(destinataire.id)}>Supprimer</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>Aucune adresse email enregistrée.</p>
  )}
</div>

        </div>
        </form>
      </div>
    </div>
  );
};

export default EmailManager;
