import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import './SupprimerUtilisateur.css'; // Assurez-vous d'avoir un fichier CSS pour le style

const DeleteUser = ({ showNavbar = true }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [showModal, setShowModal] = useState(false); // État pour afficher le modal
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Récupérer les utilisateurs depuis l'API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
      }
    };
    fetchUsers();
  }, []);

  // Gérer l'ouverture et la fermeture du modal
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // Gérer la suppression
  const handleDelete = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setErrorMessage("Veuillez sélectionner un utilisateur.");
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/${selectedUser}`);
      setSuccessMessage('Utilisateur supprimé avec succès.');
      setErrorMessage('');
      setUsers(users.filter((user) => user.username !== selectedUser));
      setSelectedUser('');
      closeModal(); // Fermer le modal après suppression
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      setErrorMessage('Erreur lors de la suppression de l\'utilisateur.');
    }
  };

  return (
    <div>
      {showNavbar && <Navbar />}
      <div className="form-box">
      <h2>Supprimer un utilisateur</h2>
        <form onSubmit={handleDelete}>
        <div className="form-container2">
          <div className="form-group">
            <select
              id="userSelect"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              required
            >
              <option value="">-- Sélectionnez un utilisateur --</option>
              {users.map((user) => (
                <option key={user.username} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>
          <button type="button" className="btn-submit" onClick={openModal} disabled={!selectedUser}>
            Supprimer
          </button>
        </div>
        </form>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>

      {/* Modal de confirmation */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Êtes-vous sûr de vouloir supprimer l'utilisateur <b>{selectedUser}</b> ?</h3>
            <div className="modal-buttons">
              <div className="col1">
              <div id="AAA">
              <button className="btn-confirm" onClick={handleDelete}>
                Oui
              </button>
              </div>
              <div id="AAA">
              <button className="btn-cancel" onClick={closeModal}>
                Non
              </button>
              </div>
             </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default DeleteUser;
